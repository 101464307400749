/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          $('.owl-carousel-home').owlCarousel({
              autoplay: true,
              dots: true,
              nav: false,
              loop:true,
              items:1
          })

          $('.owl-carousel-equipment').owlCarousel({
              autoplayTimeout: 7000,
              autoplay: true,
              dots: true,
              nav: true,
              loop:true,
              margin:45,
              nav:true,
              items:2,
              navText: "",
              responsive:{
                      0:{
                          items:1,
                          margin: 20,
                      },
                      660:{
                          items:1,
                          margin: 25,
                      },
                      768:{
                          margin: 30,
                      }
              }
          })

          // $('#modalGallery').owlCarousel({
          //     autoplay: true,
          //     dots: true,
          //     nav: false,
          //     loop:true,
          //     items:1
          // })

          jQuery(document).ready(function ($) {

              // REMOVE AND ADD CLICK EVENT
              $('.doAddItem').on('click', function () {
                  $(".gridder").data('gridderExpander').gridderAddItem('TEST');
              });

              // Call Gridder
              $(".gridder").gridderExpander({
                  scrollOffset: 60,
                  scrollTo: "listitem", // "panel" or "listitem"
                  animationSpeed: 400,
                  nextText: "",                   // Next button text
                  prevText: "",
                  closeText: 'X',
                  animationEasing: "easeInOutExpo",
                  onStart: function () {
                      console.log("Gridder Inititialized");
                  },
                  onContent: function () {
                      console.log("Gridder Expanded");
                      $('.js-enquire-trigger-service').click(function(e) {

                        e.preventDefault();
                        
                        var subject = $(this).closest('.contact-block').siblings('.service-item-title').text();
                        $(location).attr('href', document.location.origin + '/enquire-us?subject=' + subject);
    
                      });
                  },
                  onClosed: function () {
                      console.log("Gridder Closed");
                  }
              });


              $('.js-enquire-trigger').click(function(e) {
               
                e.preventDefault();

                var pageType = $(this).data('page');

                if(pageType == 'list') {
                  e.preventDefault();
                  var subject = $(this).closest('.extra-info').siblings('.equip-title').text();
                  $(location).attr('href', document.location.origin + '/enquire-us?subject=' + subject);
                }
                else if (pageType == 'single') {
                  e.preventDefault();
                  var subject = $('.page-title').text();
                  $(location).attr('href', document.location.origin + '/enquire-us?subject=' + subject);
                }
                
              });
          });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.
